<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 33 30"
  >
    <path
      fill="currentColor"
      d="m16.5 24.9-.2.1-.1-.1C8.6 17.9 3.5 13.4 3.5 8.8c0-3.2 2.4-5.6 5.6-5.6 2.5 0 4.9 1.6 5.7 3.8h3a6.3 6.3 0 0 1 5.7-3.8c3.2 0 5.6 2.4 5.6 5.6 0 4.6-5 9.2-12.6 16Zm7-24.9a9.6 9.6 0 0 0-7.2 3.3A9.6 9.6 0 0 0 9.1 0 8.7 8.7 0 0 0 .3 8.8c0 6 5.5 11 13.7 18.4l2.3 2.2 2.4-2.2c8.2-7.4 13.6-12.4 13.6-18.4 0-5-3.8-8.8-8.8-8.8Z"
    />
  </svg>
</template>
